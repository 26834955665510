<template>
  <div class="files">
    <div v-if="files.length > 0" class="files__image__container">
      <FileBig
        :notRedact="notRedact"
        v-for="(file, index) in files"
        :key="file"
        :file="file"
        :index="index"
      ></FileBig>
    </div>
  </div>
</template>

<script>
import FileBig from '@/components/common/FileBig.vue'

export default {
  props: ['files', 'notRedact'],
  components: {
    FileBig
  },

  data () {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = !this.isModalVisible
    }
  }
}
</script>

<style lang="scss">
.files {
  &__image__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 35px;
  }
}
</style>
