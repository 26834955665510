<template>
  <div class="file__big">
    <div v-if="convertFile.video" class="file__big__container">
      <video autoplay muted loop preload='none' class="portfolio_video" poster="/img/EnterLogo.a8ceaa01.svg">
        <source :src="getFile" type='video/mp4' />
        <source :src="getFile"  type="video/webm" />
        <source :src="getFile" type="video/ogg">
        <p>Your user agent does not support the HTML5 Video element.</p>
      </video>
      <img @click="openModal" src="@/assets/image/eye.svg" />
      <a-popconfirm
          v-if="!notRedact"
          title="Удалить фото из портфолио?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="deleteFile"
          placement="bottomLeft"
      >
        <img src="@/assets/image/basket.svg" />
      </a-popconfirm>
    </div>
    <div
        v-if="convertFile.image"
      class="file__big__container"
      :style="`background-image: url('${getFile}')`"
    >
      <img @click="openModal" src="@/assets/image/eye.svg" />
      <a-popconfirm
        v-if="!notRedact"
        title="Удалить фото из портфолио?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="deleteFile"
        placement="bottomLeft"
      >
        <img src="@/assets/image/basket.svg" />
      </a-popconfirm>
    </div>
    <a-modal v-if="convertFile.video" :visible="previewVisible" :footer="null" @cancel="handleCancel" >

      <video class="background-video" controls="controls" autoplay loop muted>
        <source :src="getFile" type="video/mp4">
        <source :src="getFile"  type="video/webm" />
        <source :src="getFile" type="video/ogg">
      </video>
      <div class="file__big__modal__text">
        {{ convertFile.text }}
      </div>
    </a-modal>
    <a-modal v-if="convertFile.image" :visible="previewVisible" :footer="null" @cancel="handleCancel" >
      <img class="file__big__modal" :src="getFile" />
      <div class="file__big__modal__text">
        {{ convertFile.text }}
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getBearer } from '@/helpers/authHelper'
import {convertToFile} from "@/helpers/file";

export default {
  props: ['file', 'notRedact', 'index'],
  data () {
    return {
      previewVisible: false,
      convertFile: {}
    }
  },
  beforeMount() {
    this.convertFile = convertToFile(this.file, this.index)
   },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    openModal () {
      this.previewVisible = true
    },
    deleteFile () {
      const url =
        process.env.VUE_APP_SERVER_URL + `/portfolios/${this.file.uuid}`
      fetch(url, {
        method: 'DELETE',

        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          this.$notification.open({
            message: 'Вы удалили файл из портфолио'
          })
          this.$store.dispatch('setMasterPortfolio', 1)
          this.$emit('close')
        })
        .catch((e) => {
          this.$notification.open({
            message: 'Не получилось удалить файл из портфолио'
          })
          console.log(e)
        })
    }
  },
  computed: {
    getFile () {
      if (this.convertFile.image) {
        return process.env.VUE_APP_SERVER_URL_FILE + this.convertFile.image
      }
      return process.env.VUE_APP_SERVER_URL_FILE + this.convertFile.video
    }
  }
}
</script>
<style lang="scss">
.antd-moda {
  width: 900px;
}
.file__big {
  &__container {
    background-size: cover;
    width: 220px;
    height: 170px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-right: 30px;
    transform: translate(0, 30%);
    transition: 0.3s;
    border: 1px solid;
    &._active {
      transform: translate(0, 0);
      opacity: 1;
      transition: all 0.3s ease 0.1s;
    }
    &:hover {
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
          180deg,
          rgb(41, 40, 40) 13.24%,
          transparent 118.5%
        );
        opacity: 0.75;
      }

      img {
        opacity: 1;
        z-index: 3;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
    img {
      opacity: 0;
      margin-right: 15px;
    }
  }
  &__modal {
    display: flex;
    flex-direction: column;
    background-size: cover;
    width: 100%;
    max-width: 860px;
    height: 100%;
    margin-top: 20px;
    &__text {
    }
  }
}

.portfolio_video {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background-video{
  width: 100%;
  height: 100%;
}

@media(max-width: 576px) {
  .file__big {
    &__container {
      width: 170px;
    }
  }
}
</style>
