<template>
  <div class="review__modal">
    <div class="review__modal__title__main">Оставить отзыв</div>
    <div>
      <div class="review__modal__rate">
        <a-rate :value="rate" @change="updateRate" />
      </div>
      <div class="review__modal__text__container">
        <Avatar :file="image"></Avatar>
        <a-form class="review__modal__form" :layout="'vertical'">
          <a-form-item :validateStatus="statusReview" :help="helpReview">
            <a-textarea
              :value="text"
              v-on:input="updateText($event)"
              placeholder="Ваше сообщение"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              class="review__modal__form__text-area"
            />
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="review__modal__button__send">
      <Button :buttonClass="'red-antd-button-without-width'" @click="submitForm"
        >Отправить</Button
      >
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue'
import axios from 'axios'
import { getBearer } from '@/helpers/authHelper'
import Button from '@/components/common/Button.vue'

export default {
  props: ['image', 'winnerId', 'tenderUUID'],
  components: {
    Avatar,
    Button
  },
  data () {
    return {
      rate: 2,
      text: '',
      statusReview: 'success',
      helpReview: ''
    }
  },
  methods: {
    updateText (e) {
      this.text = e.target.value
      this.statusReview = 'success'
      this.helpReview = ''
    },
    updateRate (value) {
      this.rate = value
    },
    closeReviewModal () {
      this.$emit('closeReviewModal')
    },

    submitForm () {
      if (this.text === '') {
        this.statusReview = 'error'
        this.helpReview = 'Пустое поле'
      }
      if (this.statusReview === 'success') {
        this.sendReview()
      }
    },

    sendReview () {
      axios
        .post(
          process.env.VUE_APP_SERVER_URL + '/reviews',
          {
            rating: this.rate,
            text: this.text,
            userId: this.winnerId,
            tenderUuid: this.tenderUUID,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getBearer()
            }
          }
        )
        .then((response) => {
          return response
        })
        .then((data) => {
          // this.draftMessage.text = ''

          this.$store.dispatch('setMessages', {
            tenderUuid: this.tenderUUID,
            userUUID: this.winnerId
          })
          this.$emit('okButtonReviewModule')
          this.$notification.open({
            message: 'Вы оставили отзыв!'
          })
        })
        .catch((err) => {
          if (err.message === 'Request failed with status code 422') {
            this.$notification.open({
              message: 'Вы уже оставляли отзыв пользователю'
            })
          } else {
            this.$notification.open({
              message: 'ошибка отправки отзыва'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.review__modal {
  &__title__main {
    margin-bottom: 30px;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__rate {
    display: flex;
    margin-left: 66px;
    margin-bottom: 23px;
  }
  &__text__container {
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;
  }

  &__button__send {
  }
  &__form {
    width: 100%;
    &__text-area {
      resize: none;
    }
  }
}
</style>
