export function isFileImage (type) {
    const arrayTypes = ['image/png', 'image/jpeg', 'image/svg']
    for (const i of arrayTypes) {
        if (i === type) {
            return true
        }
    }
}

export function isFileVideo (type) {
    const arrayTypes = [
        'video/mpeg',
        'video/ogg',
        'video/mp4',
        'video/webm',
        'video/3gp',
        'video/mov',
        'video/flv',
        'video/avi',
        'video/wmv'
    ]
    for (const i of arrayTypes) {
        if (i === type) {
            return true
        }
    }
}



export function convertToFile (file, index) {
    if (typeof file === 'string') {
        const imageTypes = ['png', 'jpeg', 'svg', 'jpg']
        const videoTypes = ['mpeg', 'ogg', 'mp4', 'webm', '3gp', 'mov', 'flv', 'avi', 'wmv']
        if (file.split('.')[1] && imageTypes.includes(file.split('.')[1])) {
            return {
                type: 'image',
                image: file,
                uid: index,
                name: file,
                status: 'done',
                url: process.env.VUE_APP_SERVER_URL_FILE + file
            }
        }
        if (file.split('.')[1] && videoTypes.includes(file.split('.')[1])) {
            return {
                type: 'video',
                video: file,
                uid: index,
                name: file,
                status: 'done',
                url: process.env.VUE_APP_SERVER_URL_FILE + file
            }
        }
        return file
    } else {
        return file
    }
}
