<template>
  <a-form class="order__modal" :layout="'vertical'">
    <a-form-item>
      <div class="order__modal__label">Стоимость вашей работы*</div>
    </a-form-item>

    <a-form-item class="order__modal__input__money__form__container">
      <a-form-item
        :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
        :validateStatus="checkingInputs.statusStartMoney"
        :help="checkingInputs.helpStartMoney"
      >
        <div class="order__modal__input__money__containder">
          <div class="order__modal__input__money__previe__text">от</div>
          <a-input
            size="large"
            class="a-form-item-input"
            v-model="form.planned_start_price"
            v-on:input="updatePlannedStartPrice($event)"
            @keypress="validateNumber"
          >
            <template v-slot:suffix>RUB</template>
          </a-input>
        </div>
      </a-form-item>

      <span
        :style="{
          display: 'inline-block',
          width: '24px',
          textAlign: 'center',
        }"
      ></span>

      <a-form-item
        :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
        :validateStatus="checkingInputs.statusEndMoney"
        :help="checkingInputs.helpEndMoney"
      >
        <div class="order__modal__input__money__containder">
          <div class="order__modal__input__money__previe__text">до</div>
          <a-input
            size="large"
            v-model="form.planned_end_price"
            v-on:input="updatePlannedEndPrice($event)"
            @keypress="validateNumber"
          >
            <template v-slot:suffix>RUB</template>
          </a-input>
        </div>
      </a-form-item>
    </a-form-item>

    <a-form-item>
      <div style="margin-top: 20px" class="order__modal__label">
        Срок выполнения работы*
      </div>
    </a-form-item>
    <a-form-item
      :validateStatus="checkingInputs.statusDate"
      :help="checkingInputs.helpDate"
      style="display: flex;"
    >
      <a-range-picker
        :placeholder="['Начать работу', 'Завершить работу']"
        v-model="form.moment"
        @change="chooseDate"
        format="YYYY-MM-DD"
        :disabled-date="disabledDate"
        size="large"
      />
    </a-form-item>

    <a-form-item>
      <div class="order__modal__label">Ваше предложение заказчику</div>
    </a-form-item>
    <a-form-item
      :validateStatus="checkingInputs.statusText"
      :help="checkingInputs.helpText"
    >
      <a-textarea
        :value="form.text"
        v-on:input="updateText($event)"
        placeholder="Ваше предложение"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        class="order__modal__text-area"
      />
    </a-form-item>

    <a-form-item>
      <div class="order__modal__label">Вложите файлы</div>
      <div class="order__modal__label__file__desc">
        Если у вас есть примеры на данный вид работ, обязательно прикрепите их
        сюда. Заказчики лучше оценят ваши возможности.
      </div>
    </a-form-item>

    <a-form-item>
      <UploadFiles
        name="Загрузить Фото"
        v-bind:files="form.files"
        @transferFiles="updateFiles"
      ></UploadFiles>
    </a-form-item>
    <div @click="handleTenderOrder">
      <Button buttonClass="red-antd-button-without-width" style="height: 40px">
        Отправить заявку
      </Button>
    </div>
  </a-form>
</template>

<script>
import UploadFiles from '@/components/common/UploadFiles.vue'
import { getBearer } from '@/helpers/authHelper'
import Button from '@/components/common/Button.vue'
export default {
  props: ['clearCheckingInputs', 'clearForm'],
  components: {
    UploadFiles,
    Button
  },

  data () {
    return {
      checkingInputs: this.clearCheckingInputs,
      form: this.clearForm
    }
  },
  watch: {
    form() {

    }
  },

  methods: {
    disabledDate (current) {
      return current && current.valueOf() < Date.now()
    },
    getUrlAdressFromFile (file) {
      return file.urlName
    },
    updateFiles (list) {
      this.form.files = list
    },

    handleTenderOrder () {

      if (!this.form.text || this.form.text === '') {
        this.checkingInputs.statusText = 'error'
        this.checkingInputs.helpText = 'Опишите задачу'
      }
      if (!this.form.planned_start_price) {
        this.checkingInputs.statusStartMoney = 'error'
        this.checkingInputs.helpStartMoney = 'Не выбран денежный диапазон'
      }
      if (!this.form.planned_end_price) {
        this.checkingInputs.statusEndMoney = 'error'
        this.checkingInputs.helpEndMoney = 'Не выбран денежный диапазон'
      }
      if (this.form.planned_end_price &&
          this.form.planned_start_price
          && Number(this.form.planned_end_price) <  Number(this.form.planned_start_price)) {
        this.checkingInputs.statusEndMoney = 'error'
        this.checkingInputs.helpEndMoney = 'Конечная сумма должна быть больше начальной'
      }
      if (!this.form.planned_start_date && !this.form.planned_end_date) {
        this.checkingInputs.statusDate = 'error'
        this.checkingInputs.helpDate = 'Не выбран срок работ'
      }
      if (
        this.checkingInputs.statusText === 'success' &&
        this.checkingInputs.statusStartMoney === 'success' &&
        this.checkingInputs.statusEndMoney === 'success' &&
        this.checkingInputs.statusDate === 'success'
      ) {
        this.sendTenderOrder()
      }
    },

    sendTenderOrder () {
      let sendForm = {}
      // {
      //   // files: this.form.files.map(this.getUrlAdressFromFile),
      //   moment: this.form.moment,
      //   planned_end_date: this.form.planned_end_date,
      //   planned_end_price: this.form.planned_end_price,
      //   planned_start_date: this.form.planned_start_date,
      //   planned_start_price: this.form.planned_start_price,
      //   tenderId: this.form.tenderId,
      //   text: this.form.text
      // }
      if (this.form.files && this.form.files.length !== 0) {
        sendForm = {
          files: this.form.files.map(this.getUrlAdressFromFile),
          moment: this.form.moment,
          planned_end_date: this.form.planned_end_date,
          planned_end_price: this.form.planned_end_price,
          planned_start_date: this.form.planned_start_date,
          planned_start_price: this.form.planned_start_price,
          tenderId: this.form.tenderId,
          text: this.form.text
        }
      } else {
        sendForm = {
          moment: this.form.moment,
          planned_end_date: this.form.planned_end_date,
          planned_end_price: this.form.planned_end_price,
          planned_start_date: this.form.planned_start_date,
          planned_start_price: this.form.planned_start_price,
          tenderId: this.form.tenderId,
          text: this.form.text
        }
      }

      fetch(process.env.VUE_APP_SERVER_URL + '/requests', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getBearer()
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(sendForm)
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          if (data.success) {
            this.$notification.open({
              message: 'Ваша заявка успешно отправлена'
            })
            this.$emit('okButtonOrderModule')
            this.$router.push('/main')
          } else {
            this.$notification.open({
              message: data.message
            })
          }
        })
        .catch((error) => {
          console.log(error)
          let description = error.response.status + ' '
          Object.keys(error.response.data.errors || {}).forEach((key) =>
            error.response.data.errors[key].forEach((text) => {
              description += text + ', '
            })
          )
          this.$notification.open({
            message: 'Ошибка',
            description
          })
        })
    },
    chooseDate (value, dateString) {
      this.form.moment = value
      this.form.planned_start_date = dateString[0]
      this.form.planned_end_date = dateString[1]
      this.checkingInputs.statusDate = 'success'
      this.checkingInputs.helpDate = ''
    },

    updateText (e) {
      this.form.text = e.target.value
      this.checkingInputs.statusText = 'success'
      this.checkingInputs.helpText = ''
    },

    updatePlannedStartPrice (e) {
      this.form.planned_start_price = e.target.value
      this.checkingInputs.statusStartMoney = 'success'
      this.checkingInputs.helpStartMoney = ''
      this.checkingInputs.statusEndMoney = 'success'
      this.checkingInputs.helpEndMoney = ''
    },
    updatePlannedEndPrice (e) {
      this.form.planned_end_price = e.target.value
      this.checkingInputs.statusEndMoney = 'success'
      this.checkingInputs.helpEndMoney = ''
      this.checkingInputs.statusStartMoney = 'success'
      this.checkingInputs.helpStartMoney = ''
    },
    validateNumber (e) {
      let keyCode = e.keyCode ? e.keyCode : e.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault()
      }
    },
  }
}
</script>

<style lang="scss">
.order__modal {
  &__text-area {
    resize: none;
  }
  &__main__label {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 28px;
    margin-bottom: 38px;
  }

  &__label {
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: start;
    &__file__desc{
      color: rgba(0, 0, 0, 0.58);
      margin-top: 10px;
      text-align: start;
    }
  }

  &__input__money__form__container {
    width: 80%;
    height: 40px;
  }

  &__input__money__containder {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  &__input__money__previe__text {
    margin-right: 12px;
  }
}
</style>
