<template>
  <div class="upload__files__container">
    <a-upload
      :fileList="files"
      multiple
      :before-upload="beforeUpload"
      @change="handleChange"
      class="upload-list-inline"
    >
      <div class="upload-content">
        <Button class="white-antd-button-border">
          <img src="@/assets/image/uploadFile.svg" class="upload-image" />
          <div class="upload-text">{{ name }}</div>
        </Button>
      </div>
    </a-upload>
  </div>
</template>
<script>
import axios from 'axios'
import { getBearer } from '@/helpers/authHelper'

import Button from '@/components/common/Button.vue'

export default {
  props: ['name', 'files', 'isSingle'],
  components: {
    Button
  },
  data () {
    return {
      fileList: this.files,
      i: 0
    }
  },
  methods: {
    handleChange ({ file, fileList }) {
      if (file.size > 2000000) {
        this.$notification.open({
          message: 'Файл больше разрешеного размера'
        })
        return
      }
      if (this.files.length === 0) {
        this.fileList = []
      }
      if (file.status === 'removed') {
        const newImages = this.fileList.filter((image) => {
          if (image.name !== file.name) {
            return image
          } else {
            this.deleteFile(file.urlName)
          }
        })
        this.fileList = newImages
        this.$emit('transferFiles', this.fileList)
      } else {
        if (this.isSingle && this.fileList.length === 1) {
          this.$notification.open({
            message: 'Удалите файл, чтобы можно было загрузить новый!'
          })
          return
        }
        if (this.fileList === 4) {
          alert('max files')
          return
        }
        this.submitFile(file)
      }
    },
    deleteFile (file) {
      const length = file.length
      const deleteData = {
        filename: file.substring(13, length)
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_SERVER_URL + '/delete-file',
        data: deleteData
      })
        .then((res) => {
          // this.$notification.open({
          //   message: "Файл удален",
          // });
        })
        .catch((error) => {
          console.log({ error })
          // this.$notification.open({
          //   message: "Ошибка удаления файла",
          // });
        })
    },

    updateAddCommonSize (file, name) {
      this.sizeArray.push({
        name: name,
        size: file.size
      })
    },
    updateRemoveCommonSize (file) {
      const newCommonSize = this.sizeArray.filter((item) => item.name !== file.name)
      this.sizeArray = newCommonSize
    },

    beforeUpload () {
      return false
    },
    submitFile (file) {
      if (this.isFileImage(file.type)) {
        let formData = new FormData()
        formData.append('images[' + 0 + ']', file)
        axios
          .post(process.env.VUE_APP_SERVER_URL + '/images', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((res) => {
            this.fileList = [
              ...this.fileList,
              this.getImage(file.name, res.data.data[0])
            ]
            this.$emit('transferFiles', this.fileList)
          })
          .catch(function () {
            console.log('FAILURE!!')
          })
      } else if (this.isFileVideo(file.type)) {
        let formData = new FormData()
        formData.append('videos[' + 0 + ']', file)
        axios
          .post(process.env.VUE_APP_SERVER_URL + '/videos', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: getBearer()
            }
          })
          .then((res) => {
            this.fileList = [
              ...this.fileList,
              this.getVideo(file.name, res.data.data[0])
            ]
            this.$emit('transferFiles', this.fileList)
          })
          .catch(function () {
            console.log('FAILURE!!')
          })
      } else {
          this.$notification.open({
            message: 'Файл не поддерживаемого формата'
          })
      }
    },
    getImage (imageName, urlName) {
      const file = {
        uid: this.i,
        name: imageName,
        urlName: urlName,
        status: 'done',
        type: 'image',
        url: process.env.VUE_APP_SERVER_URL_FILE + urlName
      }
      this.i += 1
      return file
    },
    getVideo (imageName, urlName) {
      const file = {
        uid: this.i,
        name: imageName,
        urlName: urlName,
        status: 'done',
        type: 'video',
        url: process.env.VUE_APP_SERVER_URL_FILE + urlName
      }
      this.i += 1
      return file
    },
    isFileImage (type) {
      const arrayTypes = ['image/png', 'image/jpeg', 'image/svg']
      for (const i of arrayTypes) {
        if (i === type) {
          return true
        }
      }
    },
    isFileVideo (type) {
      const arrayTypes = [
        'video/mpeg',
        'video/ogg',
        'video/mp4',
        'video/webm',
        'video/3gp',
        'video/mov',
        'video/flv',
        'video/avi',
        'video/wmv'
      ]
      for (const i of arrayTypes) {
        if (i === type) {
          return true
        }
      }
    }
  }
}
</script>

<style lang="scss">
.ant-upload .ant-upload-select .ant-upload-select-text {
  display: flex;
  justify-content: flex-start;
}
.upload__files__container {
  .upload-image {
    height: 15px;
    width: 15px;
  }
}

.upload-content {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
}

.upload-image {
  margin-right: 11px;
}

.upload-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #b12021;
}

.ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.ant-upload {
  width: 100%;
  height: 40px;
}
</style>
