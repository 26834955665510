<template>
  <div @click="clickAvatar" class="avatar">
    <div
      :style="`background-image: url(${serverUrl + file});`"
      class="avatar__personal"
      v-if="file"
    ></div>

    <div class="avatar__empty" v-if="!file">
      <img src="@/assets/image/person-red.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['file'],

  data () {
    return {
      serverUrl: process.env.VUE_APP_SERVER_URL
    }
  },
  methods: {
    clickAvatar () {
      this.$emit('clickAvatar')
    }
  }
}
</script>
<style lang="scss">
.avatar {
  border-radius: 50%;
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  &__personal {
    border-radius: 50%;
    height: 54px;
    width: 54px;
    background-size: cover;
  }
  &__empty {
    background-color: #f0e4e1;
    border-radius: 50%;
    height: 54px;
    min-width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
